const isProduction = process.env.NODE_ENV !== 'development'

const prodEnvironment = {
  GA_MEASUREMENT_ID: 'G-70299WP13K',
  MARKETPLACE_FE_ASSETS: 'https://wpp-app-market-fe-ch-bwidow.os-dev.io/',
}
const devEnvironment = {
  GA_MEASUREMENT_ID: 'G-70299WP13K',
  MARKETPLACE_FE_ASSETS: 'http://localhost:8600/',
}

export const gaMeasurementId = isProduction
  ? process.env.REACT_APP_WPP_GA_MEASUREMENT_ID || prodEnvironment.GA_MEASUREMENT_ID
  : devEnvironment.GA_MEASUREMENT_ID

export const marketplacePublicUrl = isProduction
  ? process.env.REACT_APP_WPP_MARKETPLACE_FE_ASSETS || prodEnvironment.MARKETPLACE_FE_ASSETS
  : devEnvironment.MARKETPLACE_FE_ASSETS

export enum API_TYPES {
  WPP_MARKET_API = 'WPP_MARKET_API',
  WPP_MASTER_DATA_API = 'WPP_MASTER_DATA_API',
  WPP_TENANT_SERVICE_API = 'WPP_TENANT_SERVICE_API',
}

const developmentApi: Record<API_TYPES, string> = {
  WPP_MARKET_API: 'https://wpp-app-market-api-ch-hulk.os-dev.io/marketplace/api',
  WPP_MASTER_DATA_API: 'https://master-data-api-ch-hulk.os-dev.io/api',
  WPP_TENANT_SERVICE_API: 'https://tenant-api-ch-stable.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  WPP_MARKET_API: 'https://wpp-app-market-api-ch-bwidow.os-dev.io/marketplace/api',
  WPP_MASTER_DATA_API: 'https://master-data-api-ch-stable.os-dev.io/api',
  WPP_TENANT_SERVICE_API: 'https://tenant-api-ch-stable.os-dev.io/api',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env[`REACT_APP_${type}`] ||
    (isProduction ? productionApi[type as API_TYPES] : developmentApi[type as API_TYPES])
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
